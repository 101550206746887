import PakettipisteIcon from '@images/pakettipiste-icon.png'
import Drawer from '@mui/material/Drawer'
import NavigationLink from '@components/NavigationLink'
import { useGetProfileQuery } from "@redux/profile-api"
import { MENU_LINKS, USE_DRAWER_MEDIA_QUERY } from '@/const'
import { useSelector, useDispatch } from "react-redux"
import { uiSelector, setDrawerOpen } from '@redux/ui-slice'
import useMediaQuery from '@mui/material/useMediaQuery'
import { hasRole } from '../utils'

const MenuDrawer = () => {
    const dispatch = useDispatch()
    const { data: profile } = useGetProfileQuery()
    const { drawerOpen } = useSelector(uiSelector)
    const useDrawer = /* useMediaQuery((theme) => theme.breakpoints.down('lg')) */ useMediaQuery(USE_DRAWER_MEDIA_QUERY)
    const open = useDrawer && drawerOpen
    const onClose = () => dispatch(setDrawerOpen(false))
    return <Drawer
        anchor="left"
        open={open}
        onClose={onClose}
    >
        <div className="menu">
            <div className="logo" style={{ alignItems: "center", marginBottom: 0 }}>
                <img src={PakettipisteIcon} alt="Pakettipiste" width="80" />
            </div>
            <div>
                {
                    MENU_LINKS.map(link =>
                        link.role != null && !hasRole(link.role, profile) ? null :
                        <NavigationLink path={link.path} 
                            label={link.label} 
                            key={link.path} 
                            onClick={onClose} 
                        />
                    )
                }
            </div>
        </div>
    </Drawer>
}

export default MenuDrawer