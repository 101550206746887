import React, { useState } from 'react'
import { useSelector, useDispatch } from "react-redux"
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useGetCompaniesQuery } from "@redux/company-api"
import { 
    contextSelector, 
    setCompanyContext,
    setParentContext 
} from "@redux/context-slice"
import { api } from "@redux/api"
import { useGetProfileQuery } from "@redux/profile-api"
import IconButton from '@mui/material/IconButton'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const CompanyDropdown = ({ style }) => {
    const dispatch = useDispatch()
    const { company, parent } = useSelector(contextSelector)
    const { data: companies } = useGetCompaniesQuery()
    const { data: profile } = useGetProfileQuery()
    const [ openParents, setOpenParents ] = useState([])
    let items = companies?.length !== 1 
        ? [{ id: -1, name: "Kaikki yritykset" }]
        : [{ id: companies[0].id, name: companies[0].name }]
    if ( companies && companies.length !== 1 ) {
        items.push(...companies)
    }

    if ( profile?.roles?.includes('admin') || profile?.roles?.includes('customer-service') ) {
        const _items = []
        for ( const item of items ) {
            const _item = {...item, children: []}
            _items.push(_item)
            if ( _item.parentid ) {
                const _parent = _items.find(i => i.id === _item.parentid)
                if ( _parent ) {
                    if ( !_parent.children.length ) {
                        _parent.children.push({..._parent})
                    }
                    _parent.children.push(item)
                    _parent.id = "_" + _parent.id
                }
            }
        }
        items = _items.filter(i => !i.parentid)
    }

    const setContext = e => {
        if ( e.target.value.toString().startsWith('_') ) {
            dispatch(setParentContext(
                parseInt(e.target.value.substring(1))
            ))
        } else {
            dispatch(setCompanyContext(
                e.target.value === -1 
                    ? null 
                    : e.target.value
            ))    
        }
    }

    return <Select
        style={style}
        id="company-dropdown"
        value={
            companies?.length === 1 
                ? companies[0].id 
                : ( parent ? "_" + parent : company ) || -1
        }
        variant="standard"
        onChange={e => {
            setContext(e)
            dispatch(api.util.invalidateTags(
                ['Order', 'Parcel', 'Person', 'Customer']
            ))
        }}
        MenuProps={{className: "company-dropdown-menu"}}
    >
        {items.map((item, i) => {
            const menuItems = []
            const isParent = item.children?.length > 0
            const boxShadowArray = []
            if ( openParents.includes(item.id) ) {
                boxShadowArray.push('0px 4px 4px rgba(0, 0, 0, 0.15)')
            }
            if ( openParents.includes(items[i-1]?.id) ) {
                boxShadowArray.push('0px -2px 2px rgba(0, 0, 0, 0.05)')
            }
            menuItems.push(
                <MenuItem value={item.id} 
                    key={item.id} 
                    style={{ 
                        boxShadow: boxShadowArray.length 
                            ? boxShadowArray.join(', ') : 'unset',
                        zIndex: openParents.includes(item.id) ? 1 : 'unset',
                    }}
                >
                    <div style={{ 
                        display: 'block', 
                        lineHeight: '16px',
                        width: '100%',
                        paddingRight: '10px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'                
                    }}>
                        <div style={{ display: 'inline' }}>
                            {item.name}
                        </div>
                        { 
                            isParent && 
                                <div style={{ 
                                    fontSize: 10, 
                                    color: '#444', 
                                    fontWeight: 500,
                                    lineHeight: '14px',
                                    marginTop: 1
                                }}>
                                    Kaikki yritykset
                            </div>
                        }
                    </div>
                    {
                        isParent &&
                        <IconButton style={{ 
                            position: 'absolute', 
                            right: 5
                        }} size="small" onClick={e => {
                            if ( openParents.includes(item.id) ) {
                                setOpenParents(openParents.filter(i => i !== item.id))
                            } else {
                                setOpenParents([...openParents, item.id])
                            }
                            e.preventDefault()
                            e.stopPropagation()
                        }}>
                            <ArrowDownIcon style={{ 
                                transform: openParents.includes(item.id) 
                                    ? 'rotate(-180deg)' : 'unset' 
                            }} />
                        </IconButton>
                    }
                </MenuItem> 
            )
            if ( isParent ) {
                menuItems.push(
                    ...item.children.map(_item =>
                        <MenuItem value={_item.id} 
                            key={_item.id} 
                            style={{ 
                                display: openParents.includes(item.id) 
                                    ? 'flex' : 'none' 
                            }}>
                                <div className="child-indicator"></div>
                                <div className="child-padding"></div>{_item.name}
                        </MenuItem> 
                    )
                )
            }
            return menuItems
        })}
    </Select>
}

export default CompanyDropdown