import dot from 'dot-object'
import { endOfMonth, startOfMonth, subMonths, formatISO9075 } from 'date-fns'

export const getOpenSearchDashboardsRange = s => {
    const endOfCurrentMonthStr = `'${formatISO9075(endOfMonth(new Date()))}Z'`
    const RANGE_MAP = {
        "1M": {
            from: `'${formatISO9075(startOfMonth(new Date()))}Z'`,
            to: `'${formatISO9075(endOfMonth(new Date()))}Z'`
        },
        "now-3M": {
            from: `'${formatISO9075(startOfMonth(subMonths(new Date(), 3 - 1)))}Z'`,
            to: endOfCurrentMonthStr
        },
        "now-6M": {
            from: `'${formatISO9075(startOfMonth(subMonths(new Date(), 6 - 1)))}Z'`,
            to: endOfCurrentMonthStr
        },
        "now-12M": {
            from: `'${formatISO9075(startOfMonth(subMonths(new Date(), 12 - 1)))}Z'`,
            to: endOfCurrentMonthStr
        }
    }
    return RANGE_MAP[s] || RANGE_MAP["now-12M"]
}

export const removeNullAndUndefined = obj => 
    Object.entries(obj).reduce((a,[k,v]) => (v == null ? a : (a[k]=v, a)), {})

export const removeProperties = (obj, ...keys) => {
    const newObj = { ...obj }
    keys.forEach(key => delete newObj[key])
    return newObj
}

export const tagProvider = (type, idField="id") => result => {
    const a = result || []
    return a.length === 0 
        ? [{ type, [idField]: -1 }]
        : a.map(({ [idField]: id }) => ({ type, id }))
}

export const getPageTitle = (subtitle, title="Pakettipiste Extranet") => 
    !subtitle ? title : `${subtitle} | ${title}`

export const isPathActive = (path, location) =>
    location.pathname === path || location.pathname.startsWith(path + '/')

export const dottify = obj => {
    const _obj = dot.dot(obj || {})
    for ( const key of Object.keys(_obj) ) {
        if ( _obj[key] === '' || _obj[key] == null ) {
            delete _obj[key]
        } else if ( _obj[key] instanceof Date ) {
            _obj[key] = _obj[key].toISOString()
        } else if ( typeof _obj[key] === 'object' ) {
            delete _obj[key]
        }
    }
    return _obj
}

export const getHashParams = location => 
    location.hash?.substring(1).split(';').reduce((acc, param) => {
        const [key, value] = param.split('=')
        if ( key ) {
            acc[key] = value
        /*
            acc[key] = parseInt(value) == value 
                ? parseInt(value) : value
        */
        }
        return acc
    }, {})

export const logout = () => {
    const OSD_LOGOUT_URL = '/dashboards/auth/openid/logout'
    const EXT_LOGOUT_URL = '/logout'
    fetch(OSD_LOGOUT_URL, { method: 'GET' })
        .finally(() => window.location = EXT_LOGOUT_URL)
}

export const hasRole = (roles, profile) => profile?.roles?.includes('admin') || 
    ( typeof roles === 'string' && profile?.roles?.includes(roles) ) ||
    ( typeof roles === 'object' && profile?.roles?.some(role => roles.includes(role)) )
